<template>
  <div class="detail">
    <v-img class="detail__icon" :src="user.icon" />
    <p class="detail__name">{{ user.name }}</p>
    <h2 class="detail__profile">プロフィール</h2>
    <p class="detail__about" v-html="createAutolink(user.profile)"></p>
  </div>
</template>

<script>
import url from '@/assets/lib/url'

export default {
  mixins: [url],
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$route.params.uid
    },
    /**
     * @return {Object} ユーザー情報
     */
    user () {
      return this.$store.getters['users/user'](this.uid)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.detail {
  width: 100%;
  text-align: left;
  &__icon {
    width: 140px;
    height: 140px;
    margin: 0 auto;
    background-color: $gray_color;
    border-radius: 50%;
  }
  &__name {
    margin: 0;
    margin-top: 20px;
    font-size: 2.4rem;
    text-align: center;
  }
  &__profile {
    margin: 0;
    margin-top: 30px;
    font-size: 1.2rem;
    color: $orange_color;
  }
  &__about {
    margin: 0;
    margin-top: 5px;
    font-size: 1.2rem;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
}
</style>
