<template>
  <div class="profile" v-if="!isProcessing">
    <profile-detail />
  </div>
</template>

<script>
import ProfileDetail from '@/components/profile/detail'

export default {
  name: 'profile',
  components: { ProfileDetail },
  async mounted () {
    // 存在しないユーザーであればnotfoundへ飛ばす
    if (!this.user) await this.$router.push({ name: 'notfound' })

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$route.params.uid
    },
    /**
     * @return {Object} ユーザー情報
     */
    user () {
      return this.$store.getters['users/user'](this.uid)
    },
    /**
     * @return {Boolean} 同期処理が必要な情報を取得中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.profile {
  width: 100vw;
  max-width: $max_width;
  padding: $padding_height $padding_width;
  margin: $header_height auto 0;
}
</style>
